import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "breadcrumb"
    }}>{`Breadcrumb`}</h1>
    <p>{`Indicate the current page’s location within a navigational hierarchy that automatically adds separators via CSS.`}</p>
    <ul className='breadcrumb box mb-3'>
  <li>
    <a href='#' onClick={() => false}>
      Projects
    </a>
  </li>
  <li>
    <a href='#' onClick={() => false}>
      List
    </a>
  </li>
  <li>
    Campaign
  </li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<ul
  class='breadcrumb box'
>
  <li>
    <a href='#'>
      Projects
    </a>
  </li>
  <li>
    <a href='#'>
      List
    </a>
  </li>
  <li>
    Campaign
  </li>
</ul>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      